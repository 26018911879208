import type { MapValueExpression } from '@trackback/widgets';
import { createNamedExpressionResolver } from '../types';
import { appendContext } from '@app/expressions/parser';

export const mapValueResolver =
  createNamedExpressionResolver<MapValueExpression>(
    'mapVal',
    (parser, options, onData, [input, output, contextKey]) =>
      parser.parseWithCallback(
        input,
        {
          error: onData.error,
          next: resolvedInput =>
            parser.parseWithCallback(
              output,
              {
                error: onData.error,
                next: onData.next,
              },
              appendContext(options, { [contextKey]: resolvedInput })
            ),
        },
        options
      )
  );
